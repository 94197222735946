<template>
  <div id="requirementTraceForm">
    <div class="d-flex m-25">
      <!-- Available Trace -->
      <div class="w-50 d-flex flex-column">
        <div>
          <h6 class="d-inline">
            Specification
          </h6>
          <span class="float-right font-small-2">
            Click the 'x' to show all requirements
          </span>
          <b-form-group>
            <v-select
              v-model="selectedSpecification"
              :disabled="loadingRequirements"
              :loading="loadingRequirements"
              label="title"
              :options="allSpecifications"
            >
              <template #spinner="{ loadingRequirements }">
                <div
                  v-if="loadingRequirements"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <div class="w-100 d-inline-flex justify-content-between">
            <h6>
              <b-badge variant="info" class="mr-50">
                {{ allRequirements.length }}
              </b-badge>
              <span>Available Requirement{{ allRequirements | handlePluralSuffix }}</span>
            </h6>
            <div>
              <b-button
                size="sm"
                variant="flat-primary"
                class="text-nowrap"
                @click="fetchAvailableRequirements(selectedSpecification)"
              >
                <feather-icon icon="RefreshCcwIcon" class="pr-25" />
                Refresh
              </b-button>
              <b-button
                size="sm"
                variant="flat-primary"
                class="text-nowrap"
                @click="selectedRequirements = []"
              >
                Clear selected
              </b-button>
            </div>
          </div>
          <b-form-input
            v-model="filterRequirementString"
            placeholder="Search..."
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
          />
          <b-form-select
            v-model="selectedRequirements"
            :options="filteredRequirementsList"
            :disabled="loadingRequirements"
            class="h-100"
            style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
            multiple
          />
        </div>
      </div>

      <!-- Assign button -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          v-b-popover.hover.top="'Assign trace requirements'"
          variant="flat-success"
          :disabled="!selectedRequirements.length"
          @click="addTraceLink"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>
      </div>

      <!-- Traced Requirements -->
      <div class="w-50">
        <h6>
          <b-badge variant="info" class="mr-50">
            {{ tracedItems.length }}
          </b-badge>
          <span>Linked Requirement{{ tracedItems | handlePluralSuffix }}</span>
        </h6>
        <vue-perfect-scrollbar
          v-if="tracedItems.length > 0"
          class="scroll-area"
          :settings="{
            maxScrollbarLength: 60,
            wheelPropagation: false,
          }"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(trace, index) in tracedItems"
              :key="index"
            >
              <div class="d-flex flex-column">
                <!-- Trace display_id & Link Type selection -->
                <div class="d-inline-flex w-100 justify-content-between">
                  <div>
                    <abbr
                      v-b-popover.hover.bottom.html="`${trace.node.object_text}`"
                      :title="trace.node.display_id"
                      class="mr-1 text-nowrap font-weight-bolder text-primary"
                    >
                      {{ trace.node.display_id }}
                    </abbr>
                  </div>
                  <div class="d-inline-flex">
                    <b-form inline class="mr-1">
                      <label class="mr-sm-1" for="inline-form-custom-select-pref">Link type</label>
                      <b-form-select
                        v-model="trace.link.type"
                        size="sm"
                        :options="linkTypeOptions"
                      />
                    </b-form>
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      @click="removeTrace(trace)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>

                <!-- Trace link justification -->
                <div class="form-label-group mt-1 mb-0">
                  <b-form-textarea
                    id="txtJustification"
                    v-model="trace.link.justification"
                    :rows="1"
                    size="sm"
                    placeholder="Trace link justification"
                  />
                  <label for="label-textarea">Justification</label>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>

        <div v-else>
          <p class="mt-1 ml-1 text-muted">
            No traced Requirements...
          </p>
        </div>
      </div>
    </div>

    <!-- Form submission buttons (for non-modal only) -->
    <div v-if="!isModal" class="mt-2 d-flex flex-row-reverse">
      <b-button
        variant="success"
        @click="onSubmit"
      >
        Update Trace
      </b-button>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'RequirementTraceForm',
  /**
   * This form handles the trace allocation for Requirements.
   * It can be used to update a single or multiple requirements.
   */
  directives: { Ripple },
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    requirementIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    forward: {
      // Trace direction, default to 'backward' trace
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params

    // Specification --
    const allSpecifications = ref([])
    const fetchSpecifications = () => {
      store
        .dispatch('specifications/fetchSpecificationsSimple')
        .then(() => {
          allSpecifications.value = store.state.specifications.specifications
        })
    }
    fetchSpecifications()

    const selectedSpecification = ref({})
    watch(selectedSpecification, specObj => {
      if (specObj) {
        fetchAvailableRequirements(specObj.id)
      } else {
        fetchAvailableRequirements()
      }
    })
    // -- ./specification

    // Available requirements --
    const selectedRequirements = ref([])
    const allRequirements = ref([])
    const filteredRequirementsList = ref([])
    const loadingRequirements = ref(false)
    const fetchAvailableRequirements = specId => {
      loadingRequirements.value = true
      allRequirements.value = []
      store
        .dispatch('requirements/updateRequirementOptions', specId)
        .then(() => {
          // Filter out the Requirements we're assigning to form the returned list
          allRequirements.value = store.state.requirements.requirement_options
            .filter(reqOpt => !props.requirementIds.includes(reqOpt.value))

          // Updated the filtered list to include all
          filterRequirementString.value = ''
          filteredRequirementsList.value = allRequirements.value
        })
        .catch(e => {
          console.error(e)
          fetchAvailableRequirements()
        })
        .finally(() => {
          loadingRequirements.value = false
        })
    }

    // Search
    const filterRequirementString = ref('')
    watch(filterRequirementString, newValue => {
      filteredRequirementsList.value = allRequirements.value
      if (newValue !== '') {
        // Filter Requirements list by text (display_id & object_text) or value (requirement id)
        filteredRequirementsList.value = filteredRequirementsList.value.filter(a => (
          a.text.toLowerCase().includes(newValue.toLowerCase())
          || a.value.toLowerCase() === newValue.toLowerCase()
        ))
      }
    })
    // -- ./available requirements

    // Requirement context --
    const tracedItems = ref([])
    const fetchTrace = () => {
      props.requirementIds.forEach(reqId => {
        if (props.forward) {
          coreService
            .requirementsApi.getRequirementCoverage(reqId, modelId)
            .then(response => { tracedItems.value = response })
        } else {
          coreService
            .requirementsApi.getRequirementTrace(reqId, modelId)
            .then(response => { tracedItems.value = response })
        }
      })
    }
    fetchTrace()
    // -- ./requirement context

    // Form elements --
    const addTraceLink = () => {
      const selectedReqObjs = allRequirements.value.filter(
        reqObj => selectedRequirements.value.includes(reqObj.value),
      )

      selectedReqObjs.forEach(obj => {
        const reqId = obj.value

        // Assign only if it doesn't already exist on the right-side
        if (tracedItems.value.filter(tI => tI.node.id === reqId).length === 0) {
          // Map the item into what the right-side wants
          const item = {
            node: {
              id: obj.value,
              display_id: obj.display_id,
              object_text: obj.object_text,
              spec: {
                id: '',
                title: '',
              },
            },
            link: {
              type: 'trace',
              justification: '',
            },
          }

          // Add to the traced item list
          tracedItems.value.push(item)
        }
      })
    }
    const linkTypeOptions = [
      { value: 'trace', text: 'Trace' },
      { value: 'dependency', text: 'Dependency' },
      { value: 'review', text: 'Review' },
      { value: 'blocks', text: 'Blocks' },
    ]
    const removeTrace = async trace => {
      const userConfirmation = await context.root.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it',
        cancelButtonText: 'No, keep it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
      if (userConfirmation.isConfirmed === false) {
        return // User declined, do nothing.
      }

      if (trace.link.id !== undefined) {
        coreService.requirementsApi.deleteTraceLink(trace.link.id, modelId)
          .then(() => {
            // Show confirmation toast
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Trace removed',
                icon: 'CheckIcon',
                text: `
                  Removed trace from
                  ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
                `,
                variant: 'success',
              },
            })

            // Remove the item from the traced list
            tracedItems.value = tracedItems.value.filter(
              x => x.link.id !== trace.link.id,
            )
          })
          .catch(error => {
            console.error(error)
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to remove trace link',
                icon: 'XIcon',
                text: `${error}`,
                variant: 'danger',
              },
            })
          })
      } else {
        // The trace doesn't exist yet in the database, just remove the item
        tracedItems.value = tracedItems.value.filter(obj => obj.node.id !== trace.node.id)
      }
    }
    // -- ./form elements

    // Form submission --
    const updateForwardTrace = (reqId, payload) => {
      coreService.requirementsApi.createOrUpdateCoverageLinks(reqId, modelId, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Trace links updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          // Legacy call
          store.dispatch('requirements/selectRequirement', reqId)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Trace',
              text: `${error}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    }
    const updateBackwardTrace = (reqId, payload) => {
      coreService.requirementsApi.createOrUpdateTraceLinks(reqId, modelId, payload)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Trace links updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          // Legacy call
          store.dispatch('requirements/selectRequirement', reqId)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Trace',
              text: `${error}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    }
    const onSubmit = () => {
      const tracePayload = []
      // Foreach Requirement we are assigning trace/coverage to...
      props.requirementIds.forEach(reqId => {
        if (props.forward) {
          tracedItems.value.forEach(tI => {
            const t = {
              trace_from: tI.node.id,
              justification: tI.link.justification,
              type: tI.link.type.toLowerCase(),
            }
            tracePayload.push(t)
          })
          updateForwardTrace(reqId, tracePayload)
        } else {
          tracedItems.value.forEach(tI => {
            const t = {
              trace_to: tI.node.id,
              justification: tI.link.justification,
              type: tI.link.type.toLowerCase(),
            }
            tracePayload.push(t)
          })
          updateBackwardTrace(reqId, tracePayload)
        }
      })
    }
    // -- ./form submission

    return {
      // Left side
      allSpecifications,
      loadingRequirements,
      selectedSpecification,
      allRequirements,
      filterRequirementString,
      filteredRequirementsList,
      selectedRequirements,

      tracedItems,
      fetchAvailableRequirements,

      addTraceLink,
      removeTrace,
      linkTypeOptions,

      // Submit
      onSubmit,
    }
  },
}
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  max-height: 50vh;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>
