<template>
  <div>
    <slot name="label">
      <div class="d-inline-flex align-items-center w-100 justify-content-between">
        <p class="text-primary font-weight-bold ml-25 mb-0" :class="labelClass">
          {{ labelString }}
        </p>
        <feather-icon
          v-if="popoverText"
          v-b-popover.hover.html="popoverText"
          :placement="popoverDirection"
          class="labelIcon text-info mr-50"
          icon="HelpCircleIcon"
          size="16"
        />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'LabelWithHoverIcon',
  props: {
    labelString: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: null,
    },
    popoverDirection: {
      type: String,
      default: 'right',
    },
    labelClass: {
      type: String,
      default: '',
    },
  },
}
</script>
